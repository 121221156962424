import axiosClient from "config/axios";
import { regiones, comunas } from 'data/data';


const verifyRut = async dni => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.get(`/patients/check-patient-by-dni/${dni}`, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const registerPatient = async body => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.post('/patients/auto-register-patient', body, {headers});
        console.log(response);
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const linkToHapp = async (body) => {
    try {
        const headers = {
            'Content-Type': 'application/json',
        };
        const response = await axiosClient.post('/auth/login/patient/sso', body, {headers});
        return response;
    } catch (error) {
        const {response} = error;
        return response;
    }
}

const communesByReg = region => {
    const id = getIdRegBayName(region);
    if ( id !== null ) {
        return comunas.filter(comuna => comuna.reg === id);
    } 
    return null;   
}

const getIdRegBayName = region => {
    if ( region ) {
        let id = 0;
        regiones.some((reg) => {
            if (reg.name === region) {
                id = reg.id;
            }
        })
        return id;
    }
    return null;
}

export {
    verifyRut,
    registerPatient,
    linkToHapp,
    communesByReg,
    regiones,
}