import { useState, useEffect } from 'react';
import { formatRut, checkRut } from "react-rut-formatter";
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import validateFormInputs from 'hooks/inputTypes/inputTypes';
import { 
    verifyRutPatientAction, 
    createPatientAction,
    clearCreateStatusAction,
    autoLoginAppPatientAction 
} from 'store/slices/registerPatient/thunks';
import { 
    regiones, 
    communesByReg 
} from 'store/slices/registerPatient/services';

const useFormNewPatient = (initState) => {
    const [state, setState] = useState(initState);
    const [verifyRutPatientMsg, setVerifyRutPatientMsg] = useState(null);
    const [statusCreate, setStatusCreate] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [errorBaseUrlStatus, setErrorBaseUrlStatus] = useState(null);
    const [spinnerSubmit, setSpinnerSubmit] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [comunes, setComunes] = useState(null);
    const [stateReg, setStateReg] = useState(null);
    const [errors, setErrors] = useState({});
    const [showErrorMessages, setShowErrorMessages] = useState(false);
    const location = useLocation();
    const dispatch = useDispatch();
    const {
        verifyPatient,
        create,
        msgCreate,
        registrationToken,
    } = useSelector(state => state.registerPatient);

    useEffect(() => {
        if (state.region) {
            const comunes = communesByReg(state.region);
            setComunes(comunes);
        } else {
            setComunes(null);
        }
    }, [state.region])

    useEffect(() => {
        setStateReg(regiones);
    }, []);

    useEffect(() => {
        if ( location.pathname === '/registro-pacientes' && location.search !== '' ) {
            const { search } = location;
            let data = search.split('?', 2)[1];
            data = data.split('&', 2);
            const company = data[0].split('=', 2)[1];
            const branchOfficeId = data[1].split('=', 2)[1];
            if (
                (company === undefined || company === '') || 
                (branchOfficeId === undefined || branchOfficeId === '')
            ) {
                setErrorBaseUrlStatus(true);
            } else {
                setState({
                    ...state,
                    company,
                    branchOfficeId,
                });                
                setErrorBaseUrlStatus(false);
            }
        } else {
            setErrorBaseUrlStatus(true);
        }
    }, [location]);

    useEffect(() => {
        if (submit) {
            if ( Object.keys(errors).length === 0 ){
                setShowErrorMessages(false);
                setSpinnerSubmit(true);
                setTimeout(() => {
                    setSpinnerSubmit(false);
                }, 800);
                createPatient();
            } else {
                setTimeout(() => {
                    setSpinnerSubmit(false);
                }, 800);
                setSubmit(false);
                setShowErrorMessages(true);
            }
        }
    }, [errors, submit]);

    useEffect(() => {
      if (showLoader) {
        setTimeout(() => {
            setShowLoader(false);    
        }, 800);
      }
    }, [showLoader]);

    useEffect(() => {
        if (create !== null) {
            if (create === true) {
                setTimeout(() => {
                    setStatusCreate(true);
                }, 1000);
                setStatusCreate(null);                
            } else {
                setTimeout(() => {
                    setStatusCreate(false);
                }, 1000);
                setStatusCreate(null);  
            }
            dispatch(clearCreateStatusAction());
        }
    }, [create])
    

    useEffect(() => {
        if (verifyPatient !== null) {
            if (verifyPatient === true) {
                setShowForm(false);
                setVerifyRutPatientMsg(true);
                setTimeout(() => {
                    setVerifyRutPatientMsg(null);
                }, 1000)
            } else {
                setShowForm(true);
                setVerifyRutPatientMsg(null);
            }
        }
      }, [verifyPatient]);
    

    const onClickShowForm = () => {
        if (state.dni === '') {
            setErrors({dni: 'Ingrese su Rut'});
        }
        if (state.dni !== '' && !checkRut(state.dni)) {
            setErrors({dni: 'El Rut no es valido'});
        }
        if (state.dni !== '' && checkRut(state.dni)) {
            setErrors({});
            setShowLoader(true);
            dispatch(verifyRutPatientAction(state.dni));
        }
    }

    const handlerChangeDni = e => {
        const dni = e.target.value;
        const formatted = formatRut(dni);
        setState({
            ...state,
            dni: formatted,
        });
    }

    const handlerChange = e => {
        setState({
            ...state,
            [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        });
    }

    const handlerChangeBirthDay = date => {
        setState({
            ...state,
            birthDay: date,
        })
    } 

    const handlerChangeNumber = e => {
        const onlyContainsNumbers = (str) => /^\d+$/.test(str);
        if (onlyContainsNumbers(e.target.value) || e.target.value === '') {
            setState({
                ...state,
                [e.target.name]: e.target.value,
            });
        }
    }

    const createPatient = () => {
        state.position = 'auto-registro';
        state.address.push({
            street: state.street,
            commune: state.commune,
            region: state.region,
            alias: 'Domicilio',
        });
        dispatch(createPatientAction(state));
        setSubmit(false);
    }
    
    const handlerSubmit = e => {
        e.preventDefault();
        const arrayErrors = validateFormInputs(state);
        setErrors(arrayErrors);
        setSubmit(true);
    }

    const handlerChangeReg = e => {
        const comunes = communesByReg(e.target.value);
        setComunes(comunes);
        setState({
            ...state,
            region: e.target.value, 
            commune: ''
        });     
    }

    const linkAutoLogin = () => {
        const body = {
            registrationToken,
            terms: true,
        }
        dispatch(autoLoginAppPatientAction(body));
    }
    
    return {
        state,
        showForm,
        showLoader,
        errors,
        comunes,
        stateReg,
        showErrorMessages,
        spinnerSubmit,
        statusCreate,
        errorBaseUrlStatus,
        msgCreate,
        verifyRutPatientMsg,
        linkAutoLogin,
        onClickShowForm,
        handlerChange,
        handlerChangeDni,
        handlerChangeReg,
        handlerChangeBirthDay,
        handlerChangeNumber,
        handlerSubmit,
    }
}
 
export default useFormNewPatient;