import { verifyRut, registerPatient, linkToHapp } from "./services";
import { 
    initVerifyRutPatient, 
    verifyRutPatient, 
    initCreatePatient, 
    createPatientSuccess,
    createPatientError,
    clearCreateStatus, 
    initAutoLogin,
    autoLoginSuccess,
    autoLoginError
} from "./registerPatientSlice";

export const verifyRutPatientAction = (dni) => {
    return async dispatch => {
        dispatch(initVerifyRutPatient());
        const response = await verifyRut(dni);
        if (response.status === 200) {
            dispatch(verifyRutPatient(true));
        } else {
            dispatch(verifyRutPatient(false));
        }
    }
}

export const createPatientAction = (body) => {
    return async dispatch => {
        dispatch(initCreatePatient());
        const response = await registerPatient(body);
        if (response.data.status === 201) {
            dispatch(createPatientSuccess(response.data));
        } else {
            dispatch(createPatientError(response.data));
        }
    }
}

export const autoLoginAppPatientAction = (body) => {
    return async dispatch => {
        dispatch(initAutoLogin());
        const response = await linkToHapp(body);
        if (response.data.statusCode === 200) {
            window.location.href = `${process.env.REACT_APP_BASE_URL_PATIENT}/sso/?token=${response.data.token}`;
        } else {
            dispatch(autoLoginError());
        }
    }
}

export const clearCreateStatusAction = () => {
    return dispatch => {
        dispatch(clearCreateStatus());
    }
}