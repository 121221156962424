import { createSlice } from '@reduxjs/toolkit';

export const registerPatientSlice = createSlice({
    name: 'registerPatient',
    initialState: {
        loading: false,
        registrationToken: null,
        verifyPatient: null,
        create: null,
        msgCreate: null,
        autoLoginResponse: '',
        autoLoginToken: null,
    },
    reducers: {
        initVerifyRutPatient: state => {
            state.loading = true;
            state.verifyPatient = null;
        },
        verifyRutPatient: (state, action) => {
            state.loading = false;
            state.verifyPatient = action.payload;
        },
        initCreatePatient: state => {
            state.loading = true;
        },
        createPatientSuccess: (state, action) => {
            state.loading = false;
            state.create = action.payload.create;
            state.registrationToken = action.payload.data.registrationToken;
            state.msgCreate = action.payload.msg;
        },
        createPatientError: (state, action) => {
            state.loading = false;
            state.create = action.payload.create;
            state.registrationToken = null;
            state.msgCreate = action.payload.msg;
        },
        initAutoLogin: (state) => {
            state.loading = true;
        },
        autoLoginSuccess: (state, action) => {
            state.autoLoginToken = action.payload;
            state.loading = false;
            state.autoLoginResponse = 'ok';
        },
        autoLoginError: (state) => {
            state.loading = false;
            state.autoLoginResponse = 'Intente de nuevo más tarde';
        },
        clearCreateStatus: state => {
            state.create = null;
        },
    }
});

export const { 
    initVerifyRutPatient,
    verifyRutPatient,
    initCreatePatient,
    createPatientSuccess,
    createPatientError,
    clearCreateStatus,
    initAutoLogin,
    autoLoginSuccess,
    autoLoginError,
 } = registerPatientSlice.actions;